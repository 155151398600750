import i18n from '@/libs/i18n'
import store from '@/store'

import { ownerTourIndexes } from './tourConstants'
// eslint-disable-next-line import/no-cycle
import { checkComponentReady, pauseTour } from './tour'
import { hideOverlay, showOverlay } from './tourHelper'

const _t = (path, page) => i18n.t(`tour.owner.${page}.${path}`)

const ownerTourSteps = (tourDriver, tourService) => [
  {
    index: ownerTourIndexes.TOUR_START,
    element: '#tour',
    popover: {
      title: _t('step 1.title', 'project-roadblock'),
      description: _t('step 1.description', 'project-roadblock'),
      onNextClick: async () => {
        const projectCount = await store.state.tour.projectCount
        checkComponentReady()
        tourDriver.drive(projectCount ? ownerTourIndexes.TOUR_SELECT_A_PROJECT : ownerTourIndexes.TOUR_CREATE_A_PROJECT)
      },
    },
  },
  {
    index: ownerTourIndexes.TOUR_CREATE_A_PROJECT,
    element: '#tour-create-project',
    popover: {
      title: _t('step 2.title', 'project-roadblock'),
      description: _t('step 2.description', 'project-roadblock'),
      nextBtnText: i18n.t('Done'),
      onNextClick: () => { pauseTour() },
    },
  },
  {
    index: ownerTourIndexes.TOUR_SELECT_A_PROJECT,
    element: '#tour-select-project',
    popover: {
      title: _t('step 3.title', 'project-roadblock'),
      description: _t('step 3.description', 'project-roadblock'),
      nextBtnText: i18n.t('Done'),
      onPrevClick: async () => {
        const projectCount = await store.state.tour.projectCount
        tourDriver.drive(projectCount ? ownerTourIndexes.TOUR_START : ownerTourIndexes.TOUR_CREATE_A_PROJECT)
      },
      onNextClick: () => {
        pauseTour()
        checkComponentReady()
      },
    },
  },
  {
    index: ownerTourIndexes.TOUR_USER_DROPDOWN,
    element: '#tour-user-dropdown',
    popover: {
      title: _t('step 1.title', 'navbar'),
      description: _t('step 1.description', 'navbar'),
      onPrevClick: async () => {
        showOverlay()
        tourService.clickBtn('tour-change-project')
        tourDriver.movePrevious()
        hideOverlay()
      },
      onNextClick: () => {
        tourService.openModal('tour-user-dropdown__BV_toggle_', 'go-next', 'dropdown')
      },
      disableButtons: ['previous'],
    },
  },
  {
    index: ownerTourIndexes.TOUR_CHANGE_PROJECT,
    element: '#tour-change-project',
    popover: {
      title: _t('step 2.title', 'navbar'),
      description: _t('step 2.description', 'navbar'),
      onPrevClick: () => {
        tourService.closeModal('tour-user-dropdown__BV_toggle_', 'go-prev', 'dropdown')
      },
      onNextClick: async () => {
        showOverlay()
        tourService.closeModal('tour-user-dropdown__BV_toggle_', null, 'dropdown')
        await tourService.pushRouter('users')
        tourDriver.moveNext()
        hideOverlay()
      },
    },
  },
  {
    index: ownerTourIndexes.TOUR_INVITE_USERS,
    element: '#tour-invite-users',
    popover: {
      title: _t('step 1.title', 'users'),
      description: _t('step 1.description', 'users'),
      onPrevClick: () => {
        tourService.openModal('tour-user-dropdown__BV_toggle_', 'go-prev', 'dropdown')
      },
    },
  },
  {
    index: ownerTourIndexes.TOUR_USERS_LIST,
    element: '#users-list-table',
    popover: {
      title: _t('step 2.title', 'users'),
      description: _t('step 2.description', 'users'),
    },
  },
  {
    index: ownerTourIndexes.TOUR_PERMISSIONS_BTN,
    element: '#tour-permissions-btn',
    popover: {
      title: _t('step 1.title', 'permissions'),
      description: _t('step 1.description', 'permissions'),
      onNextClick: async () => {
        showOverlay()
        tourService.openModal('tour-permissions-btn', null)
        await checkComponentReady()
        await tourDriver.moveNext()
        tourDriver.refresh()
        hideOverlay()
      },
    },
  },
  {
    index: ownerTourIndexes.TOUR_PERMISSIONS_FORM,
    element: '#tour-permission-form',
    popover: {
      title: _t('step 2.title', 'permissions'),
      description: _t('step 2.description', 'permissions'),
      onPrevClick: () => {
        tourService.closeModal('manage-permissions-modal___BV_modal_header_', 'go-prev')
      },
      onNextClick: async () => {
        showOverlay()
        await tourService.pushRouter('home')
        tourDriver.moveNext()
        hideOverlay()
      },
    },
  },
  {
    index: ownerTourIndexes.TOUR_MONITOR_USERS,
    element: '#tour-monitor-users',
    popover: {
      title: _t('step 1.title', 'home'),
      description: _t('step 1.description', 'home'),
      onPrevClick: async () => {
        showOverlay()
        await tourService.pushRouter('users')
        tourService.openModal('tour-permissions-btn', null)
        await checkComponentReady()
        tourDriver.movePrevious()
        tourDriver.refresh()
        hideOverlay()
      },
      onNextClick: async () => {
        showOverlay()
        await tourService.pushRouter('hours-tracked')
        tourDriver.moveNext()
        hideOverlay()
      },
    },
  },
  {
    index: ownerTourIndexes.TOUR_HOURS_TRACKED,
    element: '#tour-hours-tracked',
    popover: {
      title: _t('step 1.title', 'reports'),
      description: _t('step 1.description', 'reports'),
      onPrevClick: async () => {
        showOverlay()
        await tourService.pushRouter('home')
        tourDriver.movePrevious()
        hideOverlay()
      },
      onNextClick: async () => {
        showOverlay()
        await tourService.pushRouter('timeline')
        tourDriver.moveNext()
        tourDriver.refresh()
        hideOverlay()
      },
    },
  },
  {
    index: ownerTourIndexes.TOUR_WEEKLY_TIME,
    element: '#tour-weekly-time',
    popover: {
      title: _t('step 2.title', 'reports'),
      description: _t('step 2.description', 'reports'),
      onPrevClick: async () => {
        showOverlay()
        await tourService.pushRouter('hours-tracked')
        tourDriver.movePrevious()
        hideOverlay()
      },
      onNextClick: async () => {
        showOverlay()
        await tourService.pushRouter('attendance')
        tourDriver.moveNext()
        hideOverlay()
      },
    },
  },
  {
    index: ownerTourIndexes.TOUR_ATTENDANCE,
    element: '#tour-attendance',
    popover: {
      title: _t('step 3.title', 'reports'),
      description: _t('step 3.description', 'reports'),
      onPrevClick: async () => {
        showOverlay()
        await tourService.pushRouter('timeline')
        tourDriver.movePrevious()
        hideOverlay()
      },
      onNextClick: async () => {
        showOverlay()
        await tourService.pushRouter('idle-time')
        tourDriver.moveNext()
        window.scrollTo(0, 0)
        hideOverlay()
      },
    },
  },
  {
    index: ownerTourIndexes.TOUR_IDLE_TIME,
    element: '#tour-idle-time',
    popover: {
      title: _t('step 4.title', 'reports'),
      description: _t('step 4.description', 'reports'),
      onPrevClick: async () => {
        showOverlay()
        await tourService.pushRouter('attendance')
        tourDriver.movePrevious()
        hideOverlay()
      },
      onNextClick: async () => {
        showOverlay()
        await tourService.pushRouter('manual-time')
        tourDriver.moveNext()
        hideOverlay()
      },
    },
  },
  {
    index: ownerTourIndexes.TOUR_MANUAL_TIME,
    element: '#tour-manual-time',
    popover: {
      title: _t('step 5.title', 'reports'),
      description: _t('step 5.description', 'reports'),
      onPrevClick: async () => {
        showOverlay()
        await tourService.pushRouter('idle-time')
        tourDriver.movePrevious()
        hideOverlay()
      },
      onNextClick: async () => {
        showOverlay()
        await tourService.pushRouter('productive-rating')
        tourDriver.moveNext()
        hideOverlay()
      },
    },
  },
  {
    index: ownerTourIndexes.TOUR_PRODUCTIVITY,
    element: '#tour-productivity',
    popover: {
      title: _t('step 1.title', 'productivity'),
      description: _t('step 1.description', 'productivity'),
      onPrevClick: async () => {
        showOverlay()
        await tourService.pushRouter('manual-time')
        tourDriver.movePrevious()
        hideOverlay()
      },
      onNextClick: async () => {
        showOverlay()
        await tourService.pushRouter('payments')
        tourDriver.moveNext()
        hideOverlay()
      },
    },
  },
  {
    index: ownerTourIndexes.TOUR_PAYROLL,
    element: '#tour-payroll',
    popover: {
      title: _t('step 1.title', 'payroll'),
      description: _t('step 1.description', 'payroll'),
      onPrevClick: async () => {
        showOverlay()
        await tourService.pushRouter('productive-rating')
        tourDriver.movePrevious()
        hideOverlay()
      },
      onNextClick: () => {
        const styles = 'position: absolute; transform: translate3d(-106px, 38px, 0px); top: 0px; left: 0px; will-change: transform;'

        tourService.openModal('payment-options__BV_toggle_', 'go-next', 'dropdown', styles)
      },
    },
  },
  {
    index: ownerTourIndexes.TOUR_PAYROLL_SETTINGS,
    element: '#tour-payroll-settings',
    popover: {
      title: _t('step 2.title', 'payroll'),
      description: _t('step 2.description', 'payroll'),
      onPrevClick: () => {
        tourService.closeModal('payment-options__BV_toggle_', 'go-prev', 'dropdown')
      },
      onNextClick: async () => {
        tourService.closeModal('payment-options__BV_toggle_', null, 'dropdown')
        tourService.openModal('tour-payroll-settings')
        await checkComponentReady()
        tourDriver.moveNext()
      },
    },
  },
  {
    index: ownerTourIndexes.TOUR_MANAGE_PAYMENT,
    element: '#tour-manage-payment',
    popover: {
      title: _t('step 3.title', 'payroll'),
      description: _t('step 3.description', 'payroll'),
      onPrevClick: () => {
        const styles = 'position: absolute; transform: translate3d(-106px, 38px, 0px); top: 0px; left: 0px; will-change: transform;'

        tourService.openModal('payment-options__BV_toggle_', null, 'dropdown', styles)
        tourService.closeModal('update-payment-setting-modal___BV_modal_header_', 'go-prev')
      },
      onNextClick: () => {
        tourService.closeModal('update-payment-setting-modal___BV_modal_header_')
        const styles = 'position: absolute; transform: translate3d(-106px, 38px, 0px); top: 0px; left: 0px; will-change: transform;'

        tourService.openModal('payment-options__BV_toggle_', 'go-next', 'dropdown', styles)
      },
    },
  },
  {
    index: ownerTourIndexes.TOUR_GENERATE_INVOICE,
    element: '#tour-generate-invoice',
    popover: {
      title: _t('step 4.title', 'payroll'),
      description: _t('step 4.description', 'payroll'),
      onPrevClick: async () => {
        tourService.closeModal('payment-options__BV_toggle_', null, 'dropdown')
        tourService.openModal('tour-payroll-settings')
        await checkComponentReady()
        tourDriver.movePrevious()
      },
      onNextClick: () => {
        tourService.closeModal('payment-options__BV_toggle_', null, 'dropdown')
        tourService.openModal('tour-generate-invoice', 'go-next')
      },
    },
  },
  {
    index: ownerTourIndexes.TOUR_INVOICE_TEMPLATE,
    element: '#tour-invoice-template',
    popover: {
      title: _t('step 5.title', 'payroll'),
      description: _t('step 5.description', 'payroll'),
      onPrevClick: () => {
        const styles = 'position: absolute; transform: translate3d(-106px, 38px, 0px); top: 0px; left: 0px; will-change: transform;'

        tourService.openModal('payment-options__BV_toggle_', null, 'dropdown', styles)
        tourService.closeModal('generate-invoice-modal___BV_modal_header_', 'go-prev')
      },
      onNextClick: () => {
        tourService.closeModal('generate-invoice-modal___BV_modal_header_', 'go-next')
      },
    },
  },
  {
    index: ownerTourIndexes.TOUR_INVOICE_HISTORY,
    element: '#tour-invoice-history',
    popover: {
      title: _t('step 6.title', 'payroll'),
      description: _t('step 6.description', 'payroll'),
      onPrevClick: () => {
        tourService.openModal('tour-generate-invoice', 'go-prev')
      },
      onNextClick: async () => {
        showOverlay()
        await tourService.pushRouter('payment-history')
        tourDriver.moveNext()
        hideOverlay()
      },
    },
  },
  {
    index: ownerTourIndexes.TOUR_PAYMENT_HISTORY,
    element: '#tour-payment-history',
    popover: {
      title: _t('step 7.title', 'payroll'),
      description: _t('step 7.description', 'payroll'),
      onPrevClick: async () => {
        showOverlay()
        await tourService.pushRouter('payments')
        await checkComponentReady()
        tourDriver.movePrevious()
        hideOverlay()
      },
      onNextClick: async () => {
        showOverlay()
        await tourService.pushRouter('wallet')
        tourDriver.moveNext()
        tourDriver.refresh()
        hideOverlay()
      },
    },
  },
  {
    index: ownerTourIndexes.TOUR_WALLETS,
    element: '#tour-wallets',
    popover: {
      title: _t('step 1.title', 'accounts'),
      description: _t('step 1.description', 'accounts'),
      onPrevClick: async () => {
        showOverlay()
        await tourService.pushRouter('payment-history')
        tourDriver.movePrevious()
        hideOverlay()
      },
    },
  },
  {
    index: ownerTourIndexes.TOUR_ADD_WALLET,
    element: '#tour-add-wallet',
    popover: {
      title: _t('step 2.title', 'accounts'),
      description: _t('step 2.description', 'accounts'),
    },
  },
  {
    index: ownerTourIndexes.TOUR_WALLET_FAMILY_STATEMENT,
    element: '#tour-family-statement',
    popover: {
      title: _t('step 3.title', 'accounts'),
      description: _t('step 3.description', 'accounts'),
    },
  },
  {
    index: ownerTourIndexes.TOUR_WALLET_ACTIONS,
    element: '#tour-account-actions',
    popover: {
      title: _t('step 4.title', 'accounts'),
      description: _t('step 4.description', 'accounts'),
    },
  },
  {
    index: ownerTourIndexes.TOUR_SCHEDULED_TRANSFERS,
    element: '#tour-scheduled-transfers',
    popover: {
      title: _t('step 5.title', 'accounts'),
      description: _t('step 5.description', 'accounts'),
      onNextClick: async () => {
        showOverlay()
        await tourService.clickBtn('tour-scheduled-transfers')
        await checkComponentReady()
        tourDriver.moveNext()
        hideOverlay()
      },
    },
  },
  {
    index: ownerTourIndexes.TOUR_SCHEDULED_TRANSFERS_LIST,
    element: '#tour-scheduled-transfers-list',
    popover: {
      title: _t('step 6.title', 'accounts'),
      description: _t('step 6.description', 'accounts'),
      onPrevClick: async () => {
        const backBtn = document.getElementById('tour-scheduled-back-btn')
        if (backBtn) {
          showOverlay()
          backBtn.click()
          await checkComponentReady()
          tourDriver.movePrevious()
          hideOverlay()
        }
      },
      onNextClick: async () => {
        showOverlay()
        await tourService.pushRouter('downloads')
        tourDriver.moveNext()
        hideOverlay()
      },
    },
  },
  {
    index: ownerTourIndexes.TOUR_DOWNLOADS,
    element: '#tour-downloads',
    popover: {
      title: _t('step 1.title', 'downloads'),
      description: _t('step 1.description', 'downloads'),
      onPrevClick: async () => {
        showOverlay()
        await tourService.pushRouter('wallet')
        await tourService.clickBtn('tour-scheduled-transfers')
        await checkComponentReady()
        tourDriver.movePrevious()
        hideOverlay()
      },
    },
  },
  {
    index: ownerTourIndexes.TOUR_FINAL,
    element: '#final',
    popover: {
      title: _t('step 1.title', 'final'),
      description: _t('step 1.description', 'final'),
    },
  },
]

export default ownerTourSteps
